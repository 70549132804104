import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { UserRolePermissionNameModel } from '../../models/user-role-permission-name/user-role-permission-name.model';
import { CurrentUserPermissionsService } from '../../services/current-user-permissions/current-user-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleRoutingPermissionGuard {
  constructor(
    private router: Router,
    private currentUserPermissionsService: CurrentUserPermissionsService
  ) {}

  public canActivate(userRolePermissionName: UserRolePermissionNameModel, noAccessRedirectPath: string): UrlTree | boolean {
    const hasUserPermission: boolean =
      this.currentUserPermissionsService.getHasPermissionForSidebarItemByUserRolePermissionName(userRolePermissionName);

    if (hasUserPermission) {
      return true;
    }

    return this.router.createUrlTree([noAccessRedirectPath]);
  }
}
