import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { CurrentUserService } from '../../../users/services/current-user/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class UserAdminRoleRoutingPermissionGuard {
  constructor(
    private router: Router,
    private currentUserService: CurrentUserService
  ) {}

  public canActivate(noAccessRedirectPath: string): UrlTree | boolean {
    const isUserAdmin: boolean = this.currentUserService.getCurrentUserRoleIsAdmin();

    if (isUserAdmin) {
      return true;
    }

    return this.router.createUrlTree([noAccessRedirectPath]);
  }
}
