import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injector } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthUserService } from '../../../auth/services/auth-user/auth-user.service';
import { ApiErrorCreator } from '../../creators/api-error/api-error.creator';
import { ApiErrorDto } from '../../dtos/api-error/api-error.dto';
import { ApiErrorModel } from '../../models/api-error/api-error.model';

export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private authUserService: AuthUserService
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return this.handleError(errorResponse);
      })
    );
  }

  private handleError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    const error: ApiErrorModel = this.getErrorFromHttpResponseError(httpErrorResponse);

    if (this.is401Error(httpErrorResponse)) {
      this.authUserService.logoutFromApplication().subscribe(() => {
        this.closeAllModals();
        this.navigateToLoginPage();
      });
    }

    return throwError(() => error);
  }

  private is401Error(httpErrorResponse: HttpErrorResponse): boolean {
    return httpErrorResponse.status === 401;
  }

  private getErrorFromHttpResponseError(httpErrorResponse: HttpErrorResponse): ApiErrorModel {
    const errorDto: ApiErrorDto = httpErrorResponse.error as ApiErrorDto;

    return ApiErrorCreator.create(errorDto, httpErrorResponse.status);
  }

  private navigateToLoginPage(): void {
    this.router.navigate(['/auth/login']);
  }

  private closeAllModals(): void {
    const ngbModal: NgbModal = this.injector.get(NgbModal);

    ngbModal.dismissAll();
  }
}
