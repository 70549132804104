/* eslint-disable @typescript-eslint/typedef */
import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { MainLayoutComponent } from './layout/pages/main-layout/main-layout.component';
import { AuthNotLoggedUserGuard } from './auth/guards/auth-not-logged-user/auth-not-logged-user.guard';
import { AuthLoggedUserGuard } from './auth/guards/auth-logged-user/auth-logged-user.guard';
import { UserRoleRoutingPermissionGuard } from './user-permissions/guards/user-role-routing-permission/user-role-routing-permission.guard';
import { UserRolePermissionNameEnum } from './user-permissions/enums/user-role-permission-name/user-role-permission-name.enum';
import { UserAdminRoleRoutingPermissionGuard } from './user-permissions/guards/user-admin-role-routing-permission/user-admin-role-routing-permission.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((module) => module.AuthModule),
    canActivate: [(): Observable<boolean | UrlTree> => inject(AuthNotLoggedUserGuard).canActivate()],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'workshops',
        loadChildren: () => import('./workshops/workshops.module').then((module) => module.WorkshopsModule),
        canActivate: [
          (): UrlTree | boolean =>
            inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.workshopManagement, '/users'),
        ],
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((module) => module.UsersModule),
        canActivate: [
          (): UrlTree | boolean => inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.userManagement, '/news'),
        ],
      },
      {
        path: 'news',
        loadChildren: () => import('./news/news.module').then((module) => module.NewsModule),
        canActivate: [
          (): UrlTree | boolean =>
            inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.newsManagement, '/changelog'),
        ],
      },
      {
        path: 'changelog',
        loadChildren: () => import('./changelog/changelog.module').then((module) => module.ChangelogModule),
        canActivate: [
          (): UrlTree | boolean =>
            inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.changelogManagement, '/role-management'),
        ],
      },
      {
        path: 'role-management',
        loadChildren: () => import('./role-management/role-management.module').then((module) => module.RoleManagementModule),
        canActivate: [(): UrlTree | boolean => inject(UserAdminRoleRoutingPermissionGuard).canActivate('/material-consumption-report')],
      },
      {
        path: 'material-consumption-report',
        loadChildren: () =>
          import('./material-consumption-report/material-consumption-report.module').then(
            (module) => module.MaterialConsumptionReportModule
          ),
        canActivate: [
          (): UrlTree | boolean =>
            inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.reportManagement, '/repair-report'),
        ],
      },
      {
        path: 'repair-report',
        loadChildren: () => import('./repair-report/repair-report.module').then((module) => module.RepairReportModule),
        canActivate: [
          (): UrlTree | boolean =>
            inject(UserRoleRoutingPermissionGuard).canActivate(UserRolePermissionNameEnum.reportManagement, '/workshops'),
        ],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/workshops',
      },
    ],
    canActivate: [(): Observable<boolean | UrlTree> => inject(AuthLoggedUserGuard).canActivate()],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
