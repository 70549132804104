import { Component } from '@angular/core';

import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor(private ngbTooltipConfig: NgbTooltipConfig) {
    ngbTooltipConfig.container = 'body';
  }
}
